import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Nook ATM" />
    <h5>Sign up to be the first to hear about Nook ATM</h5>
    <form name="contact" method="POST" data-netlify="true">
      <p>
        <label>
          Your Email: <br />
          <input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          Message: <br />
          <textarea name="message"></textarea>
        </label>
      </p>
      <p>
        <button type="submit">Send</button>
      </p>
    </form>
  </Layout>
)

export default IndexPage
